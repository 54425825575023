.carnavalContainer{
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0% !important;
    
}


.textWrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageCarnavalWrapper{
    padding: 30px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: breathing 5s ease-out infinite normal;
    animation: breathing 5s ease-out infinite normal;
}
.imageCarnaval{
    width: 400px;
    height: 450px;
    object-fit: fill;
    cursor: pointer;
}

.carnavalContainer{
    width: 100%;
    height: 100%;
}
.carnavalContainerModal{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    
}
.imageCarnavalModal{
    
    object-fit: fill;
    cursor: pointer;
    
}

@-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    60% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  
  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    25% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  
    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }

@media screen and (min-width: 320px) {
    .carnavalContainer{
        padding: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        justify-content: space-evenly;
        min-height: 40vh;
        
    }
    .imageCarnavalWrapper{
        width: 80%;
        height: 55vh;
        padding: 0px;
        flex-direction: column;
    }
    .backgroundWrapper{
        position: absolute;
        width: 100vw;
        height: 100vh;
    }
    .imageCarnaval{
        width: 200px;
        height: 200px;
        object-fit: fill;
    }
    .hide-d{
        display: flex;
    }
    .hide-m{
        display: none;
    }
    .moreCarnaval{
        width: 100px;
        height: 100px;
        cursor: pointer;
        object-fit: scale-down;
    }
    .modalCarnaval{
        width: 90% !important;
    }
    .moreC{
        display: none;
    }
}

@media screen and (min-width: 400px){
    .carnavalContainer{
        padding: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        justify-content: space-evenly;
        min-height: 40vh;
        
    }
    .imageCarnavalWrapper{
        width: 80%;
        height: 52vh;
        padding: 0px;
        flex-direction: column;
        
    }
    .backgroundWrapper{
        position: absolute;
        width: 100vw;
        height: 100vh;
        
    }
}

@media screen and (min-width: 768px) {
    .carnavalContainer{
        flex-direction: row;
          text-align: left;
          justify-content: center;
          align-items: center;
          padding: 50px;
        min-height: 50vh;
        
    }
    .imageCarnavalWrapper{
        width: 35%;
        
    }
    .backgroundWrapper{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .hide-d{
        display: none;
    }
    .hide-m{
        display: flex;
    }
    .modalCarnaval{
        width: 90% !important;
    }
    .moreC{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .img-1{
        width: 300px;
        height: 500px;
        top: 5%;
        right: -5%;
        position: relative;
    }
    .img-2{
        width: 400px;
        height: 200px;
        top: -5%;
        right: -10%;
        position: relative;
    }
    .img-3{
        width: 400px;
        height: 250px;
        top: -5%;
        left: 20%;
        position: relative;
    }
    .img-4{
        width: 400px;
        height: 250px;
        top: 0%;
        right: -33%;
        position: relative;
    }
    .img-5{
        width: 400px;
        height: 200px;
        top: -3%;
        left: 40%;
        position: relative;
    }
    .img-6{
        width: 400px;
        height: 200px;
        bottom: 63%;
        right: 18%;
        position: relative;
    }
}
@media screen and (min-width: 900px) {
    .carnavalContainer{
        padding: 1%;
        min-height: 70vh;
    }
    .imageCarnaval{
        width: 400px;
        height: 450px;
        object-fit: fill;
    }
    .modalCarnaval{
        width: 900px;
    }
    .img-1{
        width: 300px;
        height: 500px;
        top: 15%;
        right: -5%;
        position: relative;
    }
    .img-2{
        width: 400px;
        height: 200px;
        top: 5%;
        right: -10%;
        position: relative;
    }
    .img-3{
        width: 400px;
        height: 250px;
        top: -3%;
        left: 15%;
        position: relative;
    }
    .img-4{
        width: 400px;
        height: 250px;
        top: 10%;
        right: -33%;
        position: relative;
    }
    .img-5{
        width: 400px;
        height: 200px;
        top: -3%;
        left: 40%;
        position: relative;
    }
    .img-6{
        width: 400px;
        height: 200px;
        bottom: 45%;
        right: 25%;
        position: relative;
    }
}
@media screen and (min-width: 1200px) {
    .carnavalContainer{
        flex-direction: row;
          text-align: left;
          justify-content: center;
          align-items: center;
          padding: 50px;
        min-height: 70vh;  
    }
    .imageCarnavalWrapper{
        width: 35%;
        
    }
    .backgroundWrapper{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .hide-d{
        display: none;
    }
    .hide-m{
        display: flex;
    }
    .modalCarnaval{
        width: 90% !important;
    }
    .moreC{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .img-1{
        width: 300px;
        height: 500px;
        top: 15%;
        right: -5%;
        position: relative;
    }
    .img-2{
        width: 400px;
        height: 200px;
        top: -0%;
        right: -10%;
        position: relative;
    }
    .img-3{
        width: 400px;
        height: 250px;
        top: -5%;
        left: 17%;
        position: relative;
    }
    .img-4{
        width: 400px;
        height: 250px;
        top: 8%;
        right: -33%;
        position: relative;
    }
    .img-5{
        width: 400px;
        height: 200px;
        top: -3%;
        left: 40%;
        position: relative;
    }
    .img-6{
        width: 400px;
        height: 200px;
        bottom: 50%;
        right: 23%;
        position: relative;
    }
}
@media screen and (min-width: 1400px) {
    .carnavalContainer{
        flex-direction: row;
          text-align: left;
          justify-content: center;
          align-items: center;
          padding: 50px;
        min-height: 70vh;  
    }
    .imageCarnavalWrapper{
        width: 35%;
        
    }
    .backgroundWrapper{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .hide-d{
        display: none;
    }
    .hide-m{
        display: flex;
    }
    .modalCarnaval{
        width: 90% !important;
    }
    .moreC{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .img-1{
        width: 300px;
        height: 500px;
        top: 5%;
        right: -5%;
        position: relative;
    }
    .img-2{
        width: 400px;
        height: 200px;
        top: -5%;
        right: -10%;
        position: relative;
    }
    .img-3{
        width: 400px;
        height: 250px;
        top: -5%;
        left: 20%;
        position: relative;
    }
    .img-4{
        width: 400px;
        height: 250px;
        top: 0%;
        right: -33%;
        position: relative;
    }
    .img-5{
        width: 400px;
        height: 200px;
        top: -3%;
        left: 40%;
        position: relative;
    }
    .img-6{
        width: 400px;
        height: 200px;
        bottom: 63%;
        right: 18%;
        position: relative;
    }
}
@media screen and (min-width: 1500px) {
    .carnavalContainer{
        flex-direction: row;
          text-align: left;
          justify-content: center;
          align-items: center;
          padding: 50px;
        min-height: 70vh;  
    }
    .imageCarnavalWrapper{
        width: 36%;
        
    }
    .backgroundWrapper{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .hide-d{
        display: none;
    }
    .hide-m{
        display: flex;
    }
    .modalCarnaval{
        width: 90% !important;
    }
    .moreC{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .img-1{
        width: 300px;
        height: 500px;
        top: 5%;
        right: -9%;
        position: relative;
    }
    .img-2{
        width: 400px;
        height: 200px;
        top: -5%;
        right: -18%;
        position: relative;
    }
    .img-3{
        width: 400px;
        height: 250px;
        top: -3%;
        left: 25%;
        position: relative;
    }
    .img-4{
        width: 400px;
        height: 250px;
        top: 30%;
        right: -0%;
        position: relative;
    }
    .img-5{
        width: 400px;
        height: 200px;
        top: 9%;
        left: 40%;
        position: relative;
    }
    .img-6{
        width: 400px;
        height: 200px;
        bottom: -10%;
        right: 18%;
        position: relative;
    }
}

@media screen and (min-width: 1500px) {
    .carnavalContainer{
        flex-direction: row;
          text-align: left;
          justify-content: center;
          align-items: center;
          padding: 50px;
        min-height: 70vh;  
    }
    .imageCarnavalWrapper{
        width: 35%;
        
    }
    .backgroundWrapper{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .hide-d{
        display: none;
    }
    .hide-m{
        display: flex;
    }
    .modalCarnaval{
        width: 90% !important;
    }
    .moreC{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .img-1{
        width: 300px;
        height: 500px;
        top: 5%;
        right: -9%;
        position: relative;
    }
    .img-2{
        width: 400px;
        height: 200px;
        top: -5%;
        right: -18%;
        position: relative;
    }
    .img-3{
        width: 400px;
        height: 250px;
        top: -3%;
        left: 25%;
        position: relative;
    }
    .img-4{
        width: 400px;
        height: 250px;
        top: 30%;
        right: -5%;
        position: relative;
    }
    .img-5{
        width: 400px;
        height: 200px;
        top: 9%;
        left: 40%;
        position: relative;
    }
    .img-6{
        width: 400px;
        height: 200px;
        bottom: -10%;
        right: 18%;
        position: relative;
    }
}