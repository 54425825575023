.eventsContainer{
  max-width: 1600px;
  height: 80vh;
  
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  
}

.eventsTitleWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventsTitle{
    
   
}

.scene{
    
    border-radius: 25px;
}

.eventsWrapper{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   z-index: 3;
}

.eventsCard{
    width: 500px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #E7107D;
    border-radius: 15px;
}

.eventsCardWrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 150px;
    width: 450px;
    flex-direction: row;
}

.eventsCardTitle{
    margin-top:15px;
    margin-bottom: 50px;
}

.coin{
    width: 200px;
    height: 200px;
    border-radius: 15px;
}

.cardInfoWrapper{
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

}

.diabloE{
    position: absolute;
    bottom: -18%;
    left: 10%;
    width: 200px;
    height: 400px;
    object-fit: scale-down;
}

.waviy {
    position: relative;
  }
  .waviy span {
    position: relative;
    display: inline-block;
    
    
    
    animation: flip 2s infinite;
    animation-delay: calc(.2s * var(--i))
  }

@media screen and (min-width: 320px){
    .eventsContainer{
        height: 75vh;
    }
    .eventsWrapper{
        flex-direction: column;
        height: 80vh;
        
    }
    .eventsCard{
        width: 310px;
        height: 210px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #E7107D;
        border-radius: 15px;
    }
    
    .eventsCardWrapper{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 150px;
        width: 100%;
        flex-direction: row;
    }
    
    .eventsCardTitle{
        margin-top:5px;
        margin-bottom: 10px;
    }
    
    .coin{
        width: 150px;
        height: 150px;
        border-radius: 15px;
    }
    .videosCoin{
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }
    .cardInfoWrapper{
        width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    
    }
    .diabloE{
        position: absolute;
        bottom: 20%;
        left: 0%;
        width: 80px;
        height: 200px;
        object-fit: scale-down;
    }
    .diablo2E{
        position: absolute;
        bottom: 20%;
        right: 0%;
        width: 80px;
        height: 200px;
        object-fit: scale-down;
    }
}

@media screen and (min-width: 400px){
.eventsContainer{
    height: 50vh;
    
}
}
/*
linear-gradient(to bottom, #cd2c81, #ab2181, #871a7d, #611877, #38166d)
*/
@media screen and (min-width: 768px){
    .eventsContainer{
        height: 80vh;
       
    }
    .eventsWrapper{
        flex-direction: row;
        height: 60vh;
    }
    .diabloE{
        position: absolute;
        bottom: 15%;
        left: 5%;
        width: 200px;
        height: 400px;
        object-fit: scale-down;
    }
    .diablo2E{
        position: absolute;
        bottom: 15%;
        right: 5%;
        width: 200px;
        height: 400px;
        object-fit: scale-down;
    }
    .eventsTitle{
        width: 80%;
    }
}

@media screen and (min-width: 1000px){
    .eventsWrapper{
        flex-direction: row;
        height: 70vh;
    }
    .eventsCard{
        width: 500px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #E7107D;
        border-radius: 15px;
    }
    
    .eventsCardWrapper{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 150px;
        width: 450px;
        flex-direction: row;
    }
    
    .eventsCardTitle{
        margin-top:15px;
        margin-bottom: 50px;
    }
    
    .coin{
        width: 200px;
        height: 200px;
        
    }
    
    .cardInfoWrapper{
        width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
}

@keyframes flip {
    0%,80% {
      transform: rotateY(360deg) 
    }
  }