.logoFooter {
  justify-content: center;
  align-items: center;
  padding-left: 0%;
  width: 50%;
}

.footer-box {
  margin-bottom: 7%;
}

.svg-logo-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  object-fit: contain;
}

.footerIcons {
  margin-top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.emailField {
  margin-top: 5%;
  color: #fece1b !important;
  border-color: #fece1b !important;
}

@media only screen and (max-width: 767px) {
  .logoFooter {
  }
}



