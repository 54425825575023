.icon {
  margin: 0 0.7rem;
  color: #fece1b;
  z-index: 1000;
}

.external-nav-link {
	color:#ff1578;
	text-decoration: none;
}

.appBar {
  z-index: 9999;
  position: absolute !important;
  box-shadow: none;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
}

.link {
  text-decoration: none;
  color: #ff1578;
  
}

/*.link :hover{
  animation: shrinkjump 2s ease-in-out;
  transform-origin: bottom center;
}*/

.navItems {
  font-family: "Rum Raisin", serif;
}

.social-pngs {
  width: 50px;
}

@keyframes shrinkjump {
  10%, 35% {
    transform: scale(2, .2) translate(0, 0);
  }
  
  45%, 50% {
    transform: scale(1) translate(0, -150px);
  }
  
  80% {
    transform: scale(1) translate(0, 0);
  }
}
