.llamaContainer{
  max-width: 1600px;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
  pointer-events: none;
}

.llamaImgCont{
    position: absolute;
    bottom: 0%;
    margin-bottom: -.5%;
}

.llamaImg{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}


@media screen and (min-width: 320px){
    .llamaContainer{
        min-height: 10vh;
        min-width: 100%;
    }
}
@media screen and (min-width: 768px){
    .llamaContainer{
        min-height: 15vh;
        min-width: 100%;
    }
}

@media screen and (min-width: 900px){
    .llamaContainer{
        min-height: 20vh;
        min-width: 100%;
    }
}

@media screen and (min-width: 1100px){
    .llamaContainer{
        min-height: 28vh;
        min-width: 100%;
    }
}