.mainCont {
    position: relative;
    max-width: 1600px;
    width: 100%;
    overflow: hidden;
    background-color: #E8154E !important;
    margin-left: auto;
    margin-right: auto;
  }
  
  .pngSection {
    position: relative;
    max-width: 1600px;
    height: 100vh;
  }
  
  .backgroundPNG {
    top: 0;
    left: 0;
    z-index: -1000;
  }
  
  .parallaxElements {
    position: absolute;
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  
  .background{
    z-index: 4;
  }
  
  .firstMountain {
    z-index: 7;
  }
  
  .secondMountain {
    
    z-index: 9;
  }
  
  
  .thirdMountain {
    z-index: 6;
  }
  
  .clouds {
    z-index: 6;
  }
  
  .suno {
    z-index: 5;
  }

  .at{
    text-decoration: none;
  }  

.originsTitle{
  position: absolute;
  z-index: 9999;
  top: 20%;
  margin-left: auto !important;
  margin-right: auto;
}

  .diablo {
    position: absolute;
    top: 20%;
    bottom: 0%;
    z-index: 8;
    height: 80%;
    left: 25%;
    margin-left: auto !important;
    margin-right: auto;
  }
  
  .diablo-mobile {
    position: absolute;
    top: 20%;
    bottom: 0%;
    z-index: 8;
    left: 25%;
    height: 100%;
    
  }
  
  .diablo-div {
    object-fit: contain;
    overflow: clip;
    min-width: 50%;
  }
  
  .logo {
    position: absolute;
    z-index: 11;
    height: 15%;
    bottom: 61%;
    left: 38%;
  }
  
  .logo-mobile {
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    object-fit: scale-down;
  }
  
  .logoMain {
    object-fit: contain;
    height: 50%;
    margin-left: -20%;
    overflow: hidden;
  }
  .hpContainer {
    height: 100vh;
  }
  
  .waviy {
    position: relative;
    color: #FFCE00;
  }
  .waviy span {
    position: relative;
    display: inline-block;
    color: #FFCE00;
    
    animation: flip 2s infinite;
    animation-delay: calc(.2s * var(--i))
  }

  @media only screen and (min-width: 320px){
    .houses{
      z-index: 10;
    }
  }

  @media only screen and (min-width: 768px){
    .houses{
      z-index: 8;
    }
  }

  @media only screen and (max-width: 767px) {
    .mainCont {
      max-width: 100vw;
      overflow: clip;
    }
  
    .pngSection {
      overflow: clip !important;
    }
  
    .hide-mobile {
      display: none !important;
    }
  
    .diablo {
      position: absolute;
      top: 0%;
      bottom: 0%;
      z-index: 8;
      height: 100%;
      left: 20%;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .hide-desktop {
      display: none;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-height: 650px) {
    .diablo {
      top: 32%;
    }
  }
  
  
  @media only screen and (max-width: 1024px) {
    .mainCont {
      max-width: 100vw !important;
      overflow: clip !important;
    }
  
    .diablo {
      max-width: 100vw;
    }
  }
@media only screen and (min-width: 1440px) {
  .originsTitle {
    font-size: 100px !important;
  }
}  
  
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg) 
  }
}