.englishContainer {
  background: transparent linear-gradient(184deg, #212765 0%, #25276E 31%, #1C427E 64%, #21699B 94%, #2271A1 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1440px) {
  .englishContainer {
    max-width: 100vw !important;
  }
}
