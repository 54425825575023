.aboutMoreContainer {
  max-width: 1600px;
  width: 70%;
  margin: auto;
  
}

@media only screen and (min-width: 768px) {
  .aboutMoreCopy {
    width: 110% !important;
    margin-left: -5% !important;
  }
}

@media only screen and (max-width: 767px) {
  .aboutMoreCopy {
    padding: 1.25rem !important;
  }
}

@media only screen and (min-width: 1440px) {
  .aboutMoreCopy {
    font-size: 50px !important;
  }
}
