.roadmapContainer {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto 3%;

}

.roadmapImg {
  width: 80%;
  margin: auto;
}

.roadmap {
  width: 100%;
  border-radius: 5%;
}
