@import url('https://fonts.googleapis.com/css2?family=Rum+Raisin&display=swap');;
@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  cursor: url('./assets/cursor2.png'), auto;
}

html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E8154E;
  overflow-x: hidden;
    overflow-y: scroll;
}

  
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera #E8154E
    background:  transparent linear-gradient(184deg, #212765 0%, #25276E 31%, #1C427E 64%, #21699B 94%, #2271A1 100%) 0% 0% no-repeat padding-box;

  */
}


@media only screen and (max-width: 767px) {
  body {
    
  }
}