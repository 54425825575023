.hoodieContainer{
  max-width: 1600px;
  min-height: 100vh;
  
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

}

.hoodieWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;

}
.sceneH{
    background: #000;
    border-radius: 25px;
}
.hoodieImgWrapper{
    width: 40%;
}

.hoodieImg{
    width: 100%;
    object-fit: scale-down;
}

@media screen and (min-width: 320px){
    .hoodieContainer{
        min-height: 95vh;
        
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
      
    }
    .hoodieWrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        margin-bottom: 10%;
    
    }
    
    .hoodieImgWrapper{
        width: 300px;
    }
    
    .hoodieImg{
        width: 100%;
        object-fit: cover;
    }  
}

@media screen and (min-width: 400px){
    .hoodieContainer{
        min-height: 60vh;
        
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 768px){
    .hoodieContainer{
        max-width: 1600px;
        
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        margin-bottom: 0px;
      }
    .hoodieWrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        margin-bottom: 10%;
    
    }
    
    .hoodieImgWrapper{
        width: 40%;
    }
    
    .hoodieImg{
        width: 100%;
        object-fit: scale-down;
    }
}
@media screen and (min-width: 900px){
    .hoodieContainer{
        min-height: 55vh;
    }
}
@media screen and (min-width: 1440px){
    .hoodieContainer{
        min-height: 90vh;
    }
}

@media screen and (min-width: 1200px){
    .hoodieImg {
        width: 100%;
    }

    .hoodieWrapper{
        margin-bottom: 24%;
    }
}