.videoContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.videoPlayer{
    width: 100%;
    height: 100%;
    
}

.copyIrl{
    position: absolute;
    bottom: 0%;
}
.titleIrl{
    position: absolute;
    top: 8%;
    margin-left: auto !important;
    margin-right: auto;
}


@media screen and (min-width: 320px){
    .videoContainer{
        height: 100vh;
    }
.videoPlayer{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
}

@media screen and (min-width: 400px){
    .videoContainer{
        height: 100vh;
    }
    .videoPlayer{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (min-width: 768px){
    .videoContainer{
        height: 100vh;
    }
.videoPlayer{
   
    width: 100%;
    height: 100%;
}
}
