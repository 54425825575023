.teamCont {
  max-width: 1600px;
  margin: auto;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 8%;
  display: flex;
  justify-content: center;
  position: relative;
}

.teamCont::-webkit-scrollbar {
  display: none;
}

.clouds {
  position: absolute;
  right: 0;
  width: 60%;
}

.clouds2 {
  position: absolute;
  top: 15%;
  right: -70%;
  width: 60%;
  z-index: 0;
  opacity: .80 !important;
}

.clouds3 {
  position: absolute;
  top: 3%;
  right: -23%;
  width: 60%;
  z-index: 2;
}

.clouds4 {
  position: absolute;
  top: -3%;
  right: 28%;
  width: 63%;
  z-index: 0;
}

.muiTeamBtn {
  width: 15rem;
  height: 15rem;
  border-radius: 50% !important;
  padding: 0px !important;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  flex-wrap: wrap;
}

.card {
  position: relative;
  width: 15rem;
  height: 15rem;
  margin: 0;
  text-align: center;
  line-height: 8rem;
  color: #ecf0f1;
  border-radius: 50%;
  transform-style: preserve-3d;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
}

.teamPng {
  width: 15rem;
  height: 15rem;
  position: relative;
  border-radius: 50%;
  transform-style: preserve-3d;
  object-fit: cover;
}

.teamCopy {
  display: flex;
  justify-content: center;
  align-items: center;
}


.pulse{
	width: 20px;
	aspect-ratio: 1;
	background-color: #56A7A7;
	border-radius: 50%;
	animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 10px #405caf;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.modalCont {
  margin-left: auto;
  margin-right: auto;
}

.cancelIcon {
  width: 52px;
  position: absolute;
  top: 3%;
  right: 2%;
  z-index: 1400;
  filter: drop-shadow(0px 5px 5px #00000029);
}

.teamAvatars {
  box-shadow: 0px 10px 10px #00000029;
}

.teamIcons {
  width: 28px;
  margin: 0% -5% -7%;
  filter: drop-shadow(0px 10px 10px #00000029);
  padding: 0%;
}

.face {
  display: block;
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  backface-visibility: hidden;
  transition: transform 0.5s ease-in-out;
}

.face--front {
  transform-style: preserve-3d;
}

.face--back {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.card:hover .face--front {
  transform: rotateY(-180deg);
}

.card:hover .face--back {
  transform: rotateY(0deg);
}

@media only screen and (max-width: 1100px) {
  .teamCont {
    overflow: hidden;
    width: 85%;
    padding: 0% 2% 10% 2%;
  }
  
}


@media only screen and (max-width: 767px) {
  
  .card {
    margin-top: 1rem;
  }

  .container {
    height: 257vh;
  }

  .hide-mobile {
    display: none;
  }

  .clouds2 {
    top: 75%;
    right: -11%;
    width: 55%;
    opacity: .7 !important;
  }

  .clouds3 {
    top: 0%;
    width: 45%;
  }

  .clouds4 {
    opacity: .5 !important;
  }
}

@media only screen and (min-width: 904px) {
  .gutter-bottom--right {
    padding-left: 18%;
  }

  .gutter-bottom--left {
    padding-right: 18%;
  }
}

@media only screen and (min-width: 1440px) {
  .teamCont {
    width: 59% !important;
  }
}

@media only screen and (min-width: 595px) and (max-width: 1100px) {
  /*.teamCont {
    width: 85% !important;
  }

  .clouds {
    width: 60%;
    transform: scaleX(-1);
  }*/
}

@media only screen and (min-width: 1101px) and (max-width: 1440px) {
  .teamCont {
    width: 60% !important;
  }
}
