.gifContainer {
  max-width: 1600px;
  width: 65%;
  margin: 0 auto;
}

.diabloGif {
  width: 90%;
  border-radius: 15%;
  margin-top: 12%;
}

@media screen and (min-width: 320px){
.hide-des{
  display: flex;
}
}
@media screen and (min-width: 768px){
  .hide-des{
    display: none;
  }
}