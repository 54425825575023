.contactContainer {
    position: relative;
    max-width: 1600px;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}
  
.at{
  text-decoration: none;

}

.press {
  color: #F8117B !important;
}

.investor {
  color: #FFFFFF !important;
}


.clic{
  cursor: pointer;
}

.contactPngSection {
    position: relative;
    max-width: 1600px;
    height: 100vh;
    
}
  
.contactBackgroundPNG {
    top: 0;
    left: 0;
    z-index: -1000;
}
  
.parallaxElements {
    position: absolute;
    height: 100vh;
    width: 100%;
    object-fit: cover;
}
  
.backgroundC{
    z-index: 3;
}
  
.firstMountainC {
    z-index: 7;
}
  
.secondMountainC {
    
    z-index: 4;
}
  
.llamas {
    z-index: 12;
    
}
  
.thirdMountainC {
    z-index: 6;
}
  
.floor {
    z-index: 6;
}
  
.sun {
    z-index: 10;
}
  
.contactTitle{
  position: absolute;
  z-index: 18;
  top: 20%;
  margin-right: auto;
  
}

.diabla {
    position: absolute;
    top: 8%;
    bottom: 0%;
    z-index: 8;
    height: 100%;
    left: 0%;
    margin-left: auto !important;
    margin-right: auto;
}
  
.diabla-mobile {
    position: fixed;
    top: -7%;
    bottom: 0%;
    z-index: 8;
    left: 4%;
    height: 100%;
    
}
  
.diabla-div {
    object-fit: contain;
    overflow: clip;
    min-width: 50%;
}
  
.logo {
    position: absolute;
    z-index: 11;
    height: 15%;
    bottom: 61%;
    left: 38%;
}
  
.logo-mobile {
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    object-fit: scale-down;
}
  
.logoMain {
    object-fit: contain;
    height: 50%;
    margin-left: -20%;
    overflow: hidden;
}
.hpContainerC {
    height: 100vh;
} 
  
@media only screen and (max-width: 767px) {
    .contactContainer {
      max-width: 100vw;
      overflow: clip;
    }
  
    .contactPngSection {
      overflow: clip !important;
    }
  
    .hide-mobile {
      display: none !important;
    }
  
    .diabla {
      position: absolute;
      top: 0%;
      bottom: 0%;
      z-index: 8;
      height: 100%;
      
    }
}
  
@media only screen and (min-width: 768px) {
    .hide-desktop {
      display: none;
    }
}
  
@media only screen and (min-width: 768px) and (max-height: 650px) {
    .diabla {
      
    }
}
  
  
@media only screen and (max-width: 1024px) {
    .contactContainer {
      max-width: 100vw !important;
      overflow: clip !important;
    }
  
    .diabla {
      max-width: 100vw;
      
    }
}

@media only screen and (min-width: 1440px) {
  
  .diabla {
    
    top: 10%;
  }
}
@media only screen and (min-width: 1500px) {
  .diabla {
    
    top: 15%;
  }
}