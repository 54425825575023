.fotpContainer {
  max-width: 1600px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
  margin-bottom: 6%;
}

.cloudsF{
  position: absolute;
  bottom: 0%;
  left: 10%;
  width: 350px;
  height: 200px;
  object-fit: fill;
  drop-shadow: 0px 6px 6px #00000029 !important;
}

.fotpWrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  
} 

.fotpGifL{
  bottom: 0%;
  position: absolute;
  width: 250px;
  height: 150px;
  border-radius: 100px;
  box-shadow: 0px 6px 6px #00000029 !important;
}

.fotpGif{
  width: 250px;
  height: 150px;
  border-radius: 100px;
  box-shadow: 0px 6px 6px #00000029 !important;
}

.fotpGifR{
  top: -40%;
  position: absolute;
  width: 250px;
  height: 150px;
  border-radius: 100px;
  box-shadow: 0px 6px 6px #00000029 !important;
}

.fotpCopy {
  margin: auto;
}
.fotpCopy2 {
  height: auto;
  margin: 0 -44% 0% 0%;

  
}

.gifContainerF{
  position: relative;
  width: 350px;
  height: 200px;
  display: flex;
  align-items: center;
}



.carouselContainer{
  min-width: 300px;
  min-height: 450px;
  display: grid;
  
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 2000px){
  .fotpGifL{
    bottom: -40%;
    position: absolute;
    width: 250px;
    height: 150px;
    border-radius: 100px;
  }
  
  .fotpGif{
    width: 250px;
    height: 150px;
    border-radius: 100px;
  }
  
  .fotpGifR{
    top: -40%;
    position: absolute;
    width: 250px;
    height: 150px;
    border-radius: 100px;
  }
}


@media only screen and (min-width: 320px) {
  .fotpContainer {
    min-height: 40vh;
    padding: 0px;
    
  }
  .carouselContainer{
    width: 80%;
    height: 300px !important;
    margin-top: 20px;
    z-index: 2;
  }    
  .fotpCopy2 {
    margin: auto; 
  }
  .fotpGif{
    width: 290px;
    height: 160px !important;
    cursor: pointer;
    border-radius: 90px;
    margin-bottom: 20px;
  }
  .carouselFoptItem{
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    
  }      
  .fotpGifL{
    display: none;
  }
  
  .fotpWrapper{
    display: none;
  }
  
  .fotpGifR{
    display: none;
  }     
  .gifContainerF{
    display: none;
  }
  .moreFotp{
    width: 100px;
    height: 100px;
    object-fit: scale-down;
    cursor: pointer;
    z-index: 5;
  }
  .moreFotpContainerD{
    display: none;
  }
  .moreFotpContainerM{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
}
@media screen and (min-width: 768px) {
  .fotpContainer{
    min-height: 40vh;
    
  }
  .carouselContainer{
      display: none;
  }
  
  .fotpCopy2{
  }
  .fotpWrapper{
    display: flex;
  }
  .moreFotpContainerM{
    display: none;
  }
  .moreFotpContainerD{
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: flex-end;
    padding-right: 150px;
    
  }
}
@media screen and (min-width: 769px) {
  .carouselContainer{
    display: none;    
  }
  .gifContainerF{
    display: none;
  }
  .fotpCopy2 {
    margin: 0 -44% 0% 0%;
  }
  .gifContainerF{
    display: flex;
  }
  .fotpGifL{
    display: flex;
  }
  .fotpGifR{
    display: flex;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .gifContainerF {
    width: 250px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .gifContainerF {
    width: 300px;
  }

  .fotpContainer {
    padding: 0% 1% !important;
  }
}

@media screen and (min-width: 900px){
  .fotpContainer {
    height: 55vh;
    padding: 0% 4%;
  }
  .fotpGifL{
    bottom: -40%;
    position: absolute;
    width: 300px;
    height: 200px;
    border-radius: 100px;
    cursor: pointer;
  }
  
  .fotpGif{
    width: 300px !important;
    height: 200px !important;
    border-radius: 100px;
    margin-bottom: 0px;
  }
  
  .fotpGifR{
    top: -40%;
    position: absolute;
    width: 300px;
    height: 200px;
    border-radius: 100px;
    cursor: pointer;
  }
}
@media screen and (min-width: 1100px){
  .fotpGifL{
    bottom: -40%;
    position: absolute;
    width: 300px;
    height: 180px;
    border-radius: 100px;
    cursor: pointer;
  }
  
  .fotpGif{
    width: 300px !important;
    height: 180px !important;
    border-radius: 100px;
    margin-bottom: 0px;
  }
  
  .fotpGifR{
    top: -40%;
    position: absolute;
    width: 300px;
    height: 180px;
    border-radius: 100px;
    cursor: pointer;
  }
}
@media screen and (min-width: 1400px){
  .fotpGifL{
    bottom: -80%;
    position: absolute;
    width: 350px;
    height: 200px;
    border-radius: 100px;
  }
  
  .fotpGif{
    width: 350px !important;
    height: 200px !important;
    border-radius: 100px;
  }
  
  .fotpGifR{
    top: -80%;
    position: absolute;
    width: 350px;
    height: 200px;
    border-radius: 100px;
  }
}