.seedContainer{  
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  
  
}

.seedCopyWrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
}

.elementsWrapper{
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

   
}

.rightS{
    width: 250px;
    height: 250px;
    
}

.griS{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.genesisWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.genesisImg{
    width: 300px;
    height: 300px;
    object-fit: scale-down;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.modalSeed{
    width: 700px;
}

@media only screen and (min-width: 320px) {
    .seedContainer{
        margin-top: 0px;
        margin-bottom: 50px;
    }
    .genesisImg{
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: scale-down;
    }
    .seedCopyWrapper{
        margin-bottom: 25px;
    }
    .genesisImg2{
        width: 200px;
        height: 200px;
        object-fit: scale-down;
    }
    .elementsWrapper{
        display: none;
    }
    .carouselS{
       
        min-width: 300px;
        height: 300px;
    }
    .modalSeed{
        width: 90% !important;
    }
    
    .moreImgSeed{
        width: 100px;
        height: 100px;
        object-fit: scale-down;
        cursor: pointer;
    }
    .moreSeedContainer{
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .seedContainer{
        margin-top: 100px;
        margin-bottom: 0px;
    } 
    .elementsWrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .carouselS{
        display: none;
    }
    .modalSeed{
        width: 800px !important;
    }
    .moreMSeed{
        display: none;
    }
    .moreSeedContainer{
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: flex-end;
        
    }
}

@media only screen and (min-width: 990px) {
    .genesisImg{
        width: 250px;
        height: 250px;
    }
    .genesisImg2{
        width: 250px;
        height: 250px;
    }
}

@media only screen and (min-width: 1100px) {
    .genesisImg{
        width: 300px;
        height: 300px;
        object-fit: cover;
    }
    .genesisImg2{
        width: 300px;
        height: 300px;
        object-fit: cover;
    }
}