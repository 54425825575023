.footer-tape-cont {
  height: 175px;
  margin-top: 0%;
  width: 100%;
  max-width: 100%;
  margin-bottom: 8%;
  background-image: url("../../../assets/Footer/footer-tape.png") !important;
  overflow: hidden;
  
}

.footer-tape {
  width: 100vw;
  overflow: clip !important;
  overflow: hidden;
}
