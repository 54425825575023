.aboutContainer {
  max-width: 1600px;
  margin-left: 0;
  margin-right: auto;
  width: 88%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2%;
  overflow: hidden;
  
}

.textPng {
  width: 15vw;
  height: auto;
}

.about-logo-m {
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;
  margin-left: 23%;
}

.freakoutCont {
}

.freakOut {
  position: absolute;
  height: 220vh;
  overflow: hidden;
}

.aboutCopyCont {
  margin: auto;
  width: 100%;
  
}

.shadowfilter {
  -webkit-filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.shadowfilter:hover {
  -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 231, 255, 0.8));
}

@media only screen and (max-width: 1600px) {
  .freakOut {
    position: absolute;
    right: 0;
    height: 210vh;
    overflow: hidden;
    /*left:calc(700px + 700 *((100vw - 768px)/672));*/
  }
}

@media only screen and (max-width: 767px) {
  .hide-mobile {
    display: none;
  }

  .freakoutCont {
    justify-content: flex-end;
    width: 87px;
    object-fit: contain;
    margin: -2% 0% 0;
    overflow-x: clip;
  }

  .textPng {
    width: 60vw;
    height: auto;
    margin-right: -12%;
    margin-left: auto;
  }

  .aboutCopy {
    margin-left: auto !important;
    margin-right: auto;
    padding: 0 3% 0 20% !important;
  }

  .aboutCopy {
    padding-right: 1.9rem !important;
  }

  .aboutCopyTitle {
    margin-left: 10% !important;
  }
}

@media only screen and (min-width: 1600px) {
}

@media only screen and (max-width: 1024px) {
  .aboutContainer {
    max-width: 100vw;
  }
}

@media only screen and (min-width: 767px) {
}

@media only screen and (min-width: 1440px) {
  .aboutCopyTitle {
    font-size: 100px !important;
  }
  .aboutCopy {
    font-size: 50px !important;
  }
}
