.merchContainerTwo{
	max-width: 1600px;
	height: 130vh ;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	display: flex;
	flex-direction: row;
	width: 100%;
	overflow: visible;
  }

  .leftContainer {
	width: 25%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: 130vh;
  }
  
  .merchTitleWrapperTwo{
	  width: 50%;
	  height: 130vh;
	  background: #FF0089;
	  display: flex;
	  flex-direction: column;
	  
  }
  
  .merchWrapper{
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: flex-end;
	  flex-direction: row;
  }
  
  .merchImgWrapper{
	  width: 400px;
  
  }

  .devilWrapperTwo{
	margin-left: auto;
	margin-right: auto;
	padding-top: 0%;
	  width: 55%;
	padding-bottom: 20%;
  }
  .patternWrapper {
	max-width: 1600px;
	width: 25%;
	background: transparent;
	overflow-y: hidden;
	min-height: 130vh;
  }

  .moreMerchTwo {
	width: 50%;
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15%;
  }

  .funnyGirlMobile {
	width: 90%;
	display: flex;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15%;
	margin-bottom: 0%;
	padding-bottom: 0%;
  }

  .funnyGirlMobileWrapper {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	margin-right: auto;
	width: 66%;
	margin-bottom: 0%;
  }
  .merchImg{
	  width: 100%;
	  margin-top: -2%;
	  
  }

  .merchDiablosImg {
	width: 100%;
	  
  }
  
  .diabloG{
	  position: absolute;
	  bottom: 0%;
	  left: 10%;
	  width: 200px;
	  height: 400px;
	  object-fit: scale-down;
  }
  .mountainM{
	  position: absolute;
	  top: -30%;
	  right: 0%;
	  width: 100%;
	  object-fit: scale-down;
  }
  
  @media screen and (min-width: 320px){
	  .merchContainer{
		  min-height: 70vh;
  
	  }
	  .merchWrapper{
		  width: 100%;
		  display: flex;
		  align-items: center;
		  justify-content: space-evenly;
		  flex-direction: column;
	  }
	  .merchImgWrapper{
		  width: 125px;
		  margin-bottom: 25px;
		  cursor: pointer;
	  }
  }
  
  @media screen and (min-width: 400px){
  .merchContainer{
	  min-height: 50vh;
	  
  }
  }

  @media screen and (max-width: 767px){
	.merchTitleWrapperTwo {
		width: 100%;
		justify-content: flex-end;
		height: 100% !important;
	}

	.merchContainerTwo {
		height: 100% !important;
	}

	.moreMerchTwo {
		margin-top: 28%;
		width: 60%
	}

	.merchContents {
		justify-content: space-between;
	}
	.merchMobileItems {
		justify-content: space-evenly;
	}

	.cloudWrapperTwo{
		margin-left: auto;
		margin-right: auto;
		margin-top: -28%;
		  width: 70%;
		  opacity: 1;
	  }
  }
  
  @media screen and (min-width: 768px){
	  .merchContainerTwo{
		  min-height: 100%;
  
	  }
	  .merchWrapper{
		  width: 100%;
		  display: flex;
		  align-items: center;
		  justify-content: space-evenly;
		  flex-direction: row;
	  }
	  .merchImgWrapper{
		  width: 400px;
		  margin-bottom: 0px;
	  }
  }
  @media screen and (min-width: 900px){
	  .merchContainer{
		  min-height: 70vh;
	  }
	  .merchWrapper{
		  width: 100%;
		  display: flex;
		  align-items: center;
		  justify-content: space-evenly;
		  flex-direction: row;
	  }
  }
  @media screen and (min-width: 1440px){
  
  }