.pressContainer{
  max-width: 1600px;
  width: 100%;
  margin: 0 auto 3%;
  height: 105vh;
  position: relative;
  margin-bottom: 5%;
}

.pressCopy{
    position: absolute;
    z-index: 8;
    top: 0%;
    
    margin: 50% auto;
    width: 100%;
    height: 10vh;
}

.diabla{
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: scale-down;
}
.diabla:hover{
  display: none;
}

.pressImg{
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: scale-down;
}
.pressPngSection {
  width: 80%;
  margin: auto;
    
}

.pressCardC{
  width: 250px;
  height: 100px;
  
  position: absolute;
}

.imgCardC{
  width: 250px;
  height: 100px;
  
  position: absolute;
}

.pressCard-1{
  bottom: 0%;
  right: 27%;
  cursor: pointer;
}

.pressCard-2{
  bottom: 0%;
  left: 25%;
  cursor: pointer;
}

.pressCard-3{
  bottom: 30%;
  left: 20%;
  cursor: pointer;
}

.pressCard-4{
  bottom: 30%;
  right: 21%;
  cursor: pointer;
}

.pressCloud-1{
  bottom: 15%;
  left: 22%;
}

.pressCloud-2{
  bottom: 15%;
  right: 20%;
}

.mediaLogo{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

@media screen and (min-width: 320px){
  .pressContainer{
    height: 75vh;
    margin-bottom: 5%;
  }
  .pressCardC{
    width: 100px;
    height: 100px;
    
    position: absolute;
  }
  .pressCardCL{
    width: 55px;
    height: 55px;
    
    position: absolute;
  }
  .pressImg{
    position: absolute;
    width: 100%;
    height: 70vh;
    object-fit: scale-down;
  }
  .pressCard-1{
    bottom: 0%;
    right: 8%;
    cursor: pointer;
  }
  .imgCardC{
    width: 100px;
    height: 100px;
    
    position: absolute;
  }
  .pressCard-2{
    bottom: 0%;
    left: 5%;
    cursor: pointer;
  }
  
  .pressCard-3{
    bottom: 23%;
    left: 1%;
    cursor: pointer;
  }
  
  .pressCard-4{
    bottom: 25%;
    right: 3%;
    cursor: pointer;
  }
  .pressCard-5{
    bottom: 40%;
    right: 42%;
    cursor: pointer;
  }
  .pressCloud-1{
    bottom: 10%;
    left: 2%;
  }
  
  .pressCloud-2{
    bottom: 10%;
    right: 0%;
  }
}

@media screen and (min-width: 350px){
  .pressCard-5{
    bottom: 45%;
    right: 43%;
    cursor: pointer;
  }
}

@media screen and (min-width: 400px){
  .pressCard-5{
    bottom: 45%;
    right: 43%;
    cursor: pointer;
  }
}

@media screen and (min-width: 600px){
  .pressContainer{
    margin-bottom: 4%;
  }
  .pressCard-5{
    bottom: 38%;
    right: 46%;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px){
  .pressContainer{
    height: 105vh;
  }
.pressCardC{
  width: 250px;
  height: 100px;
  
  position: absolute;
}
.pressCardCL{
  width: 100px;
  height: 100px;
  
  position: absolute;
}
.imgCardC{
  width: 250px;
  height: 100px;
  
  position: absolute;
}

.pressCard-1{
  bottom: 0%;
  right: 20%;
  cursor: pointer;
}

.pressCard-2{
  bottom: 0%;
  left: 20%;
  cursor: pointer;
}

.pressCard-3{
  bottom: 30%;
  left: 8%;
  cursor: pointer;
}

.pressCard-4{
  bottom: 30%;
  right: 13%;
  cursor: pointer;
}
.pressCard-5{
  bottom: 43%;
  right: 45%;
  cursor: pointer;
}
.pressCloud-1{
  bottom: 15%;
  left: 17%;
}

.pressCloud-2{
  bottom: 15%;
  right: 20%;
}

.mediaLogo{
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
}

@media screen and (min-width: 900px){
  .pressCardC{
    width: 250px;
    height: 100px;
    
    position: absolute;
  }
  .pressImg{
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: scale-down;
  }
}

@media screen and (min-width: 1300px){
  .pressCardC{
    width: 250px;
    height: 100px;
    
    position: absolute;
  }
  .pressImg{
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: scale-down;
  }
  .pressCardCL{
    width: 100px;
    height: 100px;
    
    position: absolute;
  }
  .imgCardC{
    width: 250px;
    height: 100px;
    
    position: absolute;
  }
  
  .pressCard-1{
    bottom: 0%;
    right: 27%;
    cursor: pointer;
  }
  
  .pressCard-2{
    bottom: 0%;
    left: 25%;
    cursor: pointer;
  }
  
  .pressCard-3{
    bottom: 30%;
    left: 20%;
    cursor: pointer;
  }
  
  .pressCard-4{
    bottom: 30%;
    right: 21%;
    cursor: pointer;
  }
  .pressCard-5{
    bottom: 43%;
    right: 47%;
    cursor: pointer;
  }
  .pressCloud-1{
    bottom: 15%;
    left: 22%;
  }
  
  .pressCloud-2{
    bottom: 15%;
    right: 20%;
  }
}