.jujeyCont {
  max-width: 1600px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;
  display: relative;
  height: auto;
  margin-bottom: 33%;
  
}

.jujeyImg {
  width: 100%;
}

.jujey {
  width: 100%;
}

.jujeyCard {
  position: absolute;
  height: auto;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jujuyText {
  width: 100%;
  border: 2px solid;
  border-radius: 15px;
  border-color: #fff;
  object-fit: cover;
  margin: 0;
}
.jujeyAngel {
  width: calc(100px + 30 *((100vw - 768px)/672));
  padding-bottom: 5%;
}

.jujeyCard-1 {
  width: 26%;
  position: absolute;
  left: 5%;
  margin-top: 20%;
}

.jujeyCard-2 {
  width: 30%;
  position: absolute;
  right: 3%;
  margin-top: 35%;
}

.jujeyCard-3 {
  width: 26%;
  position: absolute;
  left: 2%;
  margin-top: 70%;
}

.jujeyCard-4 {
  width: 30%;
  position: absolute;
  right: 7%;
  margin-top: 90%;
  margin-bottom: 10%;
}

.soldWrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  
}

.sold {
  font-size: 1.3rem;
}

@media only screen and (max-width: 767px) {
  .jujuyText {
    display: none;
  }

  .jujeyAngel {
    width: 80px;
  }

  
}

@media only screen and (min-width: 767px){
  .modalRm{
    width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 855px) {
  .jujeyCard-3 {
    width: 35% !important;
    margin-top: 90% !important;
  }
  .modalRm{
    width: 345px;
  }
}

@media only screen and (min-width: 860px) and (max-width: 1055px) {
  .jujeyCont {
    margin-bottom: 65% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 859px) {
  .jujeyCont {
    margin-bottom: 75% !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1215px) {
  .jujeyCard {
    width: 40% !important;
    margin-bottom: -50%;
  }

  .jujuyCopy {
    font-size: 14px !important;
  }

  .jujeyAngel {
    margin-top: 10%;
  }
  .jujeyCard-1 {
    width: 30% !important;
  }

  .jujeyCard-2 {
    width: 25% !important;
  }
  .jujeyCard-3 {
    width: 29% !important;
    margin-top: 75%;
  }

  .jujeyCont {
    margin-bottom: 40%;
  }
}

@media only screen and (min-width: 1600px) {
  .jujeyCont {
    margin-bottom: 33%;
  }
}

@media only screen and (min-width: 1800px) {
  .jujeyCont {
    margin-bottom: 37%;
  }

  .jujeyCard-4 {
    margin-top: 80%;
  }

  .jujeyAngel {
  }
}

@media only screen and (min-width: 2000px) {
  .jujeyCard-3 {
    margin-top: 50%;
  }

  .jujeyCard-4 {
    margin-top: 70%;
  }
}

@media only screen and (min-width: 2300px) {
  .jujeyCard-3 {
    margin-top: 50%;
  }

  .jujeyCard-4 {
    margin-top: 60%;
  }
}
