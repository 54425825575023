.hpMainCont {
  position: relative;
  max-width: 1600px;
  width: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.pngSection {
  position: relative;
  max-width: 1600px;
  height: 100vh;
}

.backgroundPNG {
  top: 0;
  left: 0;
  z-index: -1000;
}

.parallaxElements {
  position: absolute;
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.background{
  z-index: 1;
}

.rightMountain {
  z-index: 5;
}

.leftMountain {
  z-index: 6;
}

.buildings {
  z-index: 10;
}

.trees {
  z-index: 8;
}

.sun {
  z-index: 10;
}

.diabloHP {
  position: absolute;
  top: 9%;
  bottom: 0%;
  z-index: 8;
  height: 82%;
  left: 33%;
}

.diablo-mobile {
  position: absolute;
  top: 20%;
  bottom: 0%;
  z-index: 8;
  left: 25%;
  height: 100%;
}

.diablo-div {
  object-fit: contain;
  overflow: clip;
  min-width: 50%;
}

.logo {
  position: absolute;
  z-index: 11;
  height: 15%;
  bottom: 61%;
  left: 38%;
}

.logo-mobile {
  height: 80%;
  margin-left: auto;
  margin-right: auto;
  object-fit: scale-down;
}

.logoMain {
  object-fit: contain;
  height: 50%;
  margin-left: -20%;
  overflow: hidden;
}
.hpContainer {
  height: 100vh;
}



@media only screen and (max-width: 767px) {
  .hpMainCont {
    max-width: 100vw;
    overflow: clip;
  }

  .pngSection {
    overflow: clip !important;
  }

  .hide-mobile {
    display: none !important;
  }

  .diablo {
    position: absolute;
    top: 0%;
    bottom: 0%;
    z-index: 8;
    height: 100%;
    left: 20%;
  }
}

@media only screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-height: 650px) {
  .diabloHP {
    top: 32%;
  }
}

@media only screen and (max-width: 1186px) {
  .cloudsParallax {
    transform: none !important;
  }
}

@media only screen and (min-width: 1425px) {
  .diabloHP {
    top: 13%;
    left: 38%;
  }
}

@media only screen and (min-width: 1125px) and (max-width: 1424px) {
  .diabloHP {
    top: 12%;
    bottom: 0%;
    height: 80%;
    left: 33%;

  }
}

@media only screen and (min-width: 787px) and (max-width: 1125px) {
  .diabloHP {
    top: 12%;
    bottom: 0%;
    height: 80%;
    left: 25%;

  }
}


@media only screen and (max-width: 1024px) {
  .hpMainCont {
    max-width: 100vw !important;
    overflow: clip !important;
  }

  .diablo {
    max-width: 100vw;
  }

  .diabloHP {
    max-width: 100vw;
  }
}