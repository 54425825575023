.parallaxCloudCont {
  max-width: 100%;
  height: 20vh;
  z-index: 0;
  position: relative;
  margin-top: -15%;
}



.clouds2 {
  position: absolute;
  top: 115%;
  right: 33%;
  width: 60%;
  z-index: 0;
  opacity: 0.8 !important;
}

.clouds3 {
  position: absolute;
  top: 55%;
  right: 8%;
  width: 55%;
  z-index: 2;
}

.clouds4 {
  position: absolute;
  top: 94%;
  right: 40%;
  width: 51%;
  z-index: 0;
}


@media screen and (max-width: 767px) {
  .parallaxCloudCont {
    margin-top: -25%;
    max-width: 100%;
  }
  
  .clouds2 {
    width: 65%;
    right: 0%;
    top: 80%;
  }

  .clouds3 {
    width: 61%;
    top: 40%;
    right: 24%;
  }

 .clouds4 {
  width: 70%;
  left: 30%;
  top: 100%;
 }
  
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .parallaxCloudCont {
    margin-top: -15%;
  }
  
  .clouds2 {
    width: 50%;
    right: 40%;
    top: 80%;
  }

  .clouds3 {
    width: 57%;
    top: 25%;
    right: 10%;
  }

 .clouds4 {
  width: 55%;
  left: 42%;
  top: 130%;
 }
  
}

@media screen and (min-width: 1200px) {
  .clouds2{
    top: 145%;
    width: 50%;
    right: 5%;
  }
}