.sectionContainer{
    height: 100vh;
    width: 100%;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
}



.textWrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageSectionWrapper{
    
    
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: breathing 5s ease-out infinite normal;
    animation: breathing 5s ease-out infinite normal;
}
.imageSection{
    width: 300px;
    height: 300px;
    object-fit: fill;
    cursor: pointer;
}

.MuiBox-root {
    outline: none !important;
} 


.imageJujuyModal{
    
    object-fit: fill;
    cursor: pointer;
    
}

@-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    60% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  
  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    25% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  
    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  
    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  
  

@media screen and (min-width: 320px) {
    .sectionContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        text-align: center;
        justify-content: space-around;
        height:90vh;
    }
    .imageSectionWrapper{
        width: 35%;
        height: 60vh;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .backgroundWrapper{
        position: absolute;
        width: 100vw;
        height: 80vh;
    }
    .imageSection{
        width: 200px;
        height: 200px;
        object-fit: fill;
    }
    .moreJujuyM{
        width: 100px;
        height: 100px;
        object-fit: scale-down;
        cursor: pointer;
    }
    .moreCont{
        display: none;
    }
}

@media screen and (min-width: 400px){
    .sectionContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        text-align: center;
        justify-content: space-around;
        height: 80vh;
        
    }
    .imageSectionWrapper{
        width: 35%;
        height: 60vh;
        flex-direction: column;
        justify-content: space-evenly;
        
    }
    .backgroundWrapper{
        position: absolute;
        width: 100vw;
        height: 80vh;
        
    }
}

@media screen and (min-width: 768px) {
    .sectionContainer{
        flex-direction: row;
          text-align: left;
          justify-content: center;
          align-items: center;
          padding: 30px;
          height: 50vh;
           
    }
    .imageSectionWrapper{
        width: 37%;
        
    }
    .backgroundWrapper{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .moreJujuyM{
        display: none;
    }
    .moreCont{
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: flex-end;
    }
    .moreJujuyD{
        width: 100px;
        height: 100px;
        object-fit: scale-down;
        cursor: pointer;
    }
    .img1{
        width: 300px;
        height: 200px;
        top: 13%;
        right: -5%;
        position: relative;
    }
    .img2{
        width: 300px;
        height: 200px;
        top: 6%;
        right: -16%;
        position: relative;
    }
    .img3{
        width: 400px;
        height: 250px;
        top: 9%;
        left: 20%;
        position: relative;
    }
    .img4{
        width: 400px;
        height: 250px;
        top: 60%;
        right: 5%;
        position: relative;
    }
    .img5{
        width: 400px;
        height: 200px;
        top: 18%;
        left: 35%;
        position: relative;
    }
    .img6{
        width: 400px;
        height: 200px;
        bottom: -20%;
        right: 23%;
        position: relative;
    }
}
@media screen and (min-width: 900px) {
    .sectionContainer{
        padding: 0%;
        height: 80vh;
    }
    .imageSection{
        width: 400px;
        height: 400px;
        object-fit: fill;
    }
    .sectionContainer{
    }
    .img1{
        width: 200px;
        height: 150px;
        top: 13%;
        right: -5%;
        position: relative;
    }
    .img2{
        width: 200px;
        height: 150px;
        top: 6%;
        right: -16%;
        position: relative;
    }
    .img3{
        width: 300px;
        height: 200px;
        top: 9%;
        left: 20%;
        position: relative;
    }
    .img4{
        width: 300px;
        height: 200px;
        top: 60%;
        right: 5%;
        position: relative;
    }
    .img5{
        width: 300px;
        height: 150px;
        top: 18%;
        left: 35%;
        position: relative;
    }
    .img6{
        width: 300px;
        height: 150px;
        bottom: -20%;
        right: 23%;
        position: relative;
    }
}

@media screen and (min-width: 1200px) {
    .sectionContainer{
        padding: 0%;
    }
    .imageSection{
        width: 400px;
        height: 400px;
        object-fit: fill;
    }
    .sectionContainer{
    }
    .img1{
        width: 300px;
        height: 200px;
        top: 13%;
        right: -5%;
        position: relative;
    }
    .img2{
        width: 300px;
        height: 200px;
        top: 6%;
        right: -16%;
        position: relative;
    }
    .img3{
        width: 400px;
        height: 250px;
        top: 9%;
        left: 20%;
        position: relative;
    }
    .img4{
        width: 400px;
        height: 250px;
        top: 50%;
        right: -2%;
        position: relative;
    }
    .img5{
        width: 400px;
        height: 200px;
        top: 18%;
        left: 38%;
        position: relative;
    }
    .img6{
        width: 400px;
        height: 200px;
        bottom: -20%;
        right: 22%;
        position: relative;
    }
}

@media screen and (min-width: 1440px) {
    .sectionContainer{
        padding: 0%;
    }
    .imageSection{
        width: 400px;
        height: 400px;
        object-fit: fill;
    }
    .sectionContainer{
    }
    .img1{
        width: 300px;
        height: 200px;
        top: 13%;
        right: -5%;
        position: relative;
    }
    .img2{
        width: 300px;
        height: 200px;
        top: 6%;
        right: -16%;
        position: relative;
    }
    .img3{
        width: 400px;
        height: 250px;
        top: 9%;
        left: 20%;
        position: relative;
    }
    .img4{
        width: 400px;
        height: 250px;
        top: 60%;
        right: 5%;
        position: relative;
    }
    .img5{
        width: 400px;
        height: 200px;
        top: 18%;
        left: 35%;
        position: relative;
    }
    .img6{
        width: 400px;
        height: 200px;
        bottom: -20%;
        right: 23%;
        position: relative;
    }
}

@media screen and (min-width: 1550px) {
    .sectionContainer{
        padding: 4%;
    }
    .imageSection{
        width: 400px;
        height: 400px;
        object-fit: fill;
    }
    .sectionContainer{
        padding: 20px;
    }
    .img1{
        width: 300px;
        height: 200px;
        top: 13%;
        right: -5%;
        position: relative;
    }
    .img2{
        width: 300px;
        height: 200px;
        top: 15%;
        right: -20%;
        position: relative;
    }
    .img3{
        width: 400px;
        height: 250px;
        top: 22%;
        left: 27%;
        position: relative;
    }
    .img4{
        width: 400px;
        height: 250px;
        top: 75%;
        right: -5%;
        position: relative;
    }
    .img5{
        width: 400px;
        height: 200px;
        top: 35%;
        left: 37%;
        position: relative;
    }
    .img6{
        width: 400px;
        height: 200px;
        bottom: -40%;
        right: 20%;
        position: relative;
    }
}

@media screen and (min-width: 1800px) {
    .sectionContainer{
        padding: 4%;
    }
    .imageSection{
        width: 400px;
        height: 400px;
        object-fit: fill;
    }
    .sectionContainer{
        padding: 20px;
    }
    .img1{
        width: 300px;
        height: 200px;
        top: 13%;
        right: -5%;
        position: relative;
    }
    .img2{
        width: 300px;
        height: 200px;
        top: 15%;
        right: -20%;
        position: relative;
    }
    .img3{
        width: 400px;
        height: 250px;
        top: 22%;
        left: 27%;
        position: relative;
    }
    .img4{
        width: 400px;
        height: 250px;
        bottom: 0%;
        right: -15%;
        position: relative;
    }
    .img5{
        width: 400px;
        height: 200px;
        top:  55%;
        left: -40%;
        position: relative;
    }
    .img6{
        width: 400px;
        height: 200px;
        bottom: -40%;
        right: -10%;
        position: relative;
    }
}